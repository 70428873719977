export const textStyles = {
  largeHeadlineSerif: {
    fontFamily: 'GTSuperDisplayRegular',
    fontSize: 80,
    lineHeight: 82.8,
    letterSpacing: 0,
  },
  largeHeadlineSans: {
    fontFamily: 'Circular',
    fontSize: 80,
    lineHeight: 82.8,
    letterSpacing: 0,
    fontWeight: 700,
  },
  teaserText: {
    fontFamily: 'Circular',
    fontSize: 14,
    lineHeight: 23,
    letterSpacing: 0.3,
    fontWeight: 400,
  },
  teaserTextBold: {
    fontFamily: 'Circular',
    fontSize: 14,
    lineHeight: 23,
    letterSpacing: 0.3,
    fontWeight: 900,
  },
  teaserLink: {
    display: 'block',
    fontFamily: 'Circular',
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 2,
    fontWeight: 900,
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#000',
    '&:hover': {
      color: 'var(--primary)',
    },
  },
  categoryRegular: {
    fontFamily: 'Circular',
    fontSize: 12,
    lineHeight: 20,
    letterSpacing: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  categoryBold: {
    fontFamily: 'Circular',
    fontSize: 12,
    lineHeight: 20,
    letterSpacing: 1,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  subHeading: {
    fontFamily: 'Circular',
    fontSize: 100,
    lineHeight: 95,
    letterSpacing: 1.11,
    fontWeight: 900,
    marginBottom: 20,
  },
  subHeadingTextSerif: {
    fontFamily: 'GTSuperDisplayLight',
    fontSize: 50,
    lineHeight: 52,
    letterSpacing: 0,
  },
  subHeadingTextSans: {
    fontFamily: 'Circular',
    fontSize: 50,
    lineHeight: 52,
    letterSpacing: 0,
    fontWeight: 700,
  },
  smallTeaserHeadline: {
    fontFamily: 'GTSuperDisplayRegular',
    fontSize: 38,
    lineHeight: 40,
    letterSpacing: 0,
  },
  employeeName: {
    fontFamily: 'Circular',
    fontSize: 23,
    lineHeight: 44,
    letterSpacing: 1.15,
    fontWeight: 700,
    textShadow: `
      0 1px 1px rgba(0, 0, 0, 0.5),
      0 1px 1px rgba(0, 0, 0, 0.5)
    `,
  },
  employeeBioSerif: {
    fontFamily: 'GTSuperDisplayLight',
    fontSize: 28,
    lineHeight: 32,
    letterSpacing: 0,
  },
  employeeBioSans: {
    fontFamily: 'Circular',
    fontSize: 28,
    lineHeight: 32,
    letterSpacing: 0,
    fontWeight: 700,
  },
  jobListing: {
    fontFamily: 'GTSuperDisplayRegular',
    fontSize: 70,
    lineHeight: 72,
    letterSpacing: 0,
  },
  jobListingGenderTag: {
    fontFamily: 'Circular',
    fontSize: 10,
    lineHeight: 20,
    letterSpacing: 0.83,
    fontWeight: 700,
  },
  brandName: {
    fontFamily: 'Circular',
    fontSize: 60,
    lineHeight: 70,
    letterSpacing: 0,
    fontWeight: 900,
  },
  videoControl: {
    fontFamily: 'Circular',
    fontSize: 13,
    lineHeight: 16,
    letterSpacing: 1.86,
    fontWeight: 900,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--primary)',
    },
  },
  videoOverlay: {
    fontFamily: 'Circular',
    fontSize: 98,
    lineHeight: 124,
    letterSpacing: 0,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  largeTextHeading: {
    fontSize: 60,
    fontFamily: 'Circular',
    lineHeight: 72,
    letterSpacing: 0,
  },
  fontWeight: 700,
  largeText: {
    fontFamily: 'GTSuperDisplayLight',
    fontSize: 50,
    lineHeight: 52,
    letterSpacing: 0,
  },
}

export const desktopTemplateCols = `
  [full-start teaser-block-left-start] 133fr
  [content-start column-left-start] 60fr
  [inset-start teaser-block-right-start] 240fr
  [column-left-end] 100fr
    [column-center-start] 300fr
  [column-center-end] 100fr
  [column-right-start] 240fr
  [teaser-block-left-end inset-end] 60fr
  [column-right-end content-end] 133fr
  [teaser-block-right-end full-end]
`
