export const vwpx = (input, base = 1366) => {
  const factor = 100 / base
  return `${input * factor}vw`
}

export const fluidFontSize = (input = 16, base = 1366) => {
  const factor = 100 / base
  return `clamp(13px, ${input * factor}vw, 98px)`
}

export const randomID = () => {
  const hexCode = (Math.random() * 0xfffff * 1000000).toString(16)
  return hexCode.slice(0, 10)
}

export const handleExternalLink = event => {
  const currentHostName = window.location.hostname
  const url = new URL(event.target.href)
  const isExtern = url.hostname !== currentHostName
  if (isExtern) {
    event.preventDefault()
    window.open(url)
  }
}
