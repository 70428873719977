import styled from 'styled-components'

import { textStyles } from '@/theme'

import { fluidFontSize, vwpx } from '@/utils'

const Text = styled.div(({ type }) => {
  const {
    fontFamily,
    fontSize,
    lineHeight,
    letterSpacing,
    ...rest
  } = textStyles[type]

  return {
    fontFamily,
    fontSize: fluidFontSize(fontSize),
    lineHeight: vwpx(lineHeight),
    letterSpacing: vwpx(letterSpacing),
    ...rest,
  }
})

export default Text
