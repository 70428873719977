import React from 'react'
import styled from 'styled-components'

import Text from '@/components/Text'
import { fluidFontSize, vwpx } from '@/utils'
import FondofSquareLogo from '@/icons/fondof/FondOfLogoSquare'

const FooterListItem = styled('div')({
  fontSize: fluidFontSize(14),
  fontWeight: 400,
  lineHeight: 1.5,
  '@media(max-width: 500px)': {
    fontSize: vwpx(55),
    fontWeight: 400,
  },
})

const FooterList = styled('div')({
  listStyleType: 'none',
  margin: '0',
})

const FooterContainer = styled('div')({
  display: 'flex',
  padding: '20px 0',
  flexDirection: 'column',
  marginTop: vwpx(25),
  color: '#fff',
})

const FOLogoWrapper = styled('a')({
  width: vwpx(100),
  margin: '0 0 40px 0',
  color: '#fff',
})

const MailToEmail = styled('a')({
  color: '#fff',
  textDecoration: 'none',
  lineHeight: 1.5,
  '@media(max-width: 500px)': {
    fontSize: vwpx(55),
  },
})

const CategoryBrand = () => (
  <FooterContainer>
    <FOLogoWrapper rel="noopener" href="https://www.fondof.de">
      <FondofSquareLogo width={69} height={63} />
    </FOLogoWrapper>
    <FooterList>
      <Text type="teaserText">
        <FooterListItem>FOND OF GmbH</FooterListItem>
      </Text>
      <Text type="teaserText">
        <FooterListItem>Vitalisstraße 67</FooterListItem>
      </Text>
      <Text type="teaserText">
        <FooterListItem>50827 Köln</FooterListItem>
      </Text>
      <Text type="teaserText">
        <FooterListItem>Germany</FooterListItem>
      </Text>
    </FooterList>
    <Text
      type="teaserText"
      css={{
        marginTop: vwpx(15),
        lineHeight: 1.5,
        fontSize: fluidFontSize(14),
        '@media(max-width: 500px)': {
          fontSize: vwpx(60),
        },
      }}
    >
      <p>+49 221 9567 3227</p>
      <p
        css={{
          marginTop: vwpx(-20),
          fontSize: fluidFontSize(14),
          '@media(max-width: 500px)': {
            marginTop: vwpx(-20),
          },
        }}
      >
        <MailToEmail href="mailto:info@fondof.de">info@fondof.de</MailToEmail>
      </p>
    </Text>
    <Text
      type="teaserText"
      css={{
        marginTop: vwpx(40),
        lineHeight: 1.5,
        fontSize: fluidFontSize(14),
        '@media(max-width: 500px  )': {
          fontSize: vwpx(15, 500),
          marginBottom: vwpx(25, 500),
        },
      }}
    >
      © {new Date().getFullYear()} FOND OF GmbH
    </Text>
  </FooterContainer>
)

export default CategoryBrand
