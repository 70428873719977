import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Text from '@/components/Text'
import { fluidFontSize, vwpx } from '@/utils'

const PrivacyPoliceLinkContainer = styled('div')({
  display: 'flex',
  padding: '20px 0',
  marginTop: vwpx(15),
  listStyleType: 'none',
  color: '#fff',
  fontSize: fluidFontSize(18),
  textAlign: 'left',
  lineHeight: 1.5,
  flexDirection: 'column',
  '@media(max-width: 500px)': {
    fontSize: vwpx(20, 'vw', 500),
    fontFamily: 'Circular',
    fontWeight: 700,
  },
})

const PrivacyPolicyLink = styled(Link)({
  textDecoration: 'none',
  color: '#fff',
  margin: '10px 0',
  '@media(max-width: 500px)': {
    fontSize: vwpx(60),
    letterSpacing: '0.15em',
    fontWeight: 700,
  },
})

const PrivacyPoliceLinkExtern = styled('a')({
  textDecoration: 'none',
  color: '#fff',
  margin: '10px 0',
  '@media(max-width: 500px)': {
    fontSize: vwpx(60),
    letterSpacing: '0.15em',
    fontWeight: 700,
  },
})

const CategoryBrand = () => (
  <Text type="teaserLink">
    <PrivacyPoliceLinkContainer>
      <PrivacyPolicyLink to="/presse">PRESSE</PrivacyPolicyLink>
      <PrivacyPolicyLink to="/impressum">IMPRESSUM</PrivacyPolicyLink>
      <PrivacyPolicyLink to="/datenschutzbedingungen">
        DATENSCHUTZ
      </PrivacyPolicyLink>
      <PrivacyPoliceLinkExtern
        rel="noopener norefferer"
        target="_blank"
        href="https://partner.fondof.de/"
      >
        PARTNERSHOP
      </PrivacyPoliceLinkExtern>
    </PrivacyPoliceLinkContainer>
  </Text>
)

export default CategoryBrand
