import React from 'react'
import styled from 'styled-components'

import { vwpx } from '@/utils'

import SatchLogo from '@/icons/brands/BrandLogoSatch'
import ErgobagLogo from '@/icons/brands/BrandLogoErgobag'
import AffenzahnLogo from '@/icons/brands/BrandLogoAffenzahn'

const BrandContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const BrandLogoContainer = styled('div')({
  display: 'flex',
  padding: '20px 0',
  marginTop: vwpx(25),
})

const BrandLogoLink = styled('a')({
  marginRight: '25px',
})

const CategoryBrand = () => (
  <BrandContainer>
    <BrandLogoContainer>
      <BrandLogoLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.affenzahn.com/de"
      >
        <AffenzahnLogo />
      </BrandLogoLink>
      <BrandLogoLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.ergobag.de"
      >
        <ErgobagLogo />
      </BrandLogoLink>
    </BrandLogoContainer>
    <BrandLogoContainer>
      <BrandLogoLink
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.satch.com/de"
      >
        <SatchLogo />
      </BrandLogoLink>
    </BrandLogoContainer>
  </BrandContainer>
)

export default CategoryBrand
