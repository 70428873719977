import React from 'react'

function FondOfLogoSquare({ height, width }) {
  return (
    <svg height={height} width={width}>
      <path
        d="M2 9.508h32.453v-8.49H2zm0 16.976h32.453v-8.488H2zM2 43.46h63.577v-8.488H2zm0 19.204V52.152h6.775v2.224H4.342V56.6h3.9v2.134H4.355v3.93zM15.139 51.93c2.951 0 5.456 2.15 5.456 5.485 0 3.321-2.505 5.471-5.456 5.471-2.965 0-5.471-2.15-5.471-5.47 0-3.337 2.506-5.487 5.471-5.487m0 8.644c1.452 0 3.024-.978 3.024-3.173 0-2.193-1.572-3.187-3.024-3.187-1.468 0-3.04.994-3.04 3.187 0 2.195 1.572 3.173 3.04 3.173m13.491 2.091l-4.33-6.909v6.91h-2.327V52.151h2.847l3.943 6.39v-6.39h2.343v10.512zm4.446 0V52.152h3.81c3.158 0 5.278 2.016 5.278 5.263 0 3.248-2.12 5.25-5.292 5.25h-3.796zm3.707-2.209c1.616 0 2.936-.935 2.936-3.04s-1.32-3.054-2.936-3.054h-1.365v6.094h1.365zm14.89-8.525c2.95 0 5.456 2.15 5.456 5.485 0 3.321-2.505 5.471-5.456 5.471-2.965 0-5.471-2.15-5.471-5.47 0-3.337 2.506-5.487 5.47-5.487m0 8.644c1.453 0 3.025-.978 3.025-3.173 0-2.193-1.572-3.187-3.024-3.187-1.468 0-3.04.994-3.04 3.187 0 2.195 1.572 3.173 3.04 3.173m7.138 2.091V52.152h6.775v2.224h-4.433V56.6h3.9v2.134h-3.886v3.93zm7.713-48.871C66.524 6.175 60.35 0 52.731 0c-7.618 0-13.793 6.175-13.793 13.793 0 7.618 6.175 13.793 13.793 13.793 7.618 0 13.793-6.175 13.793-13.793m-7.633-.042a6.127 6.127 0 01-6.12 6.121 6.128 6.128 0 01-6.12-6.12 6.127 6.127 0 016.12-6.12 6.126 6.126 0 016.12 6.12"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default FondOfLogoSquare
