import React from 'react'

function BrandLogoErgobag() {
  return (
    <svg width={83} height={27}>
      <g fill="#FFF" fillRule="evenodd">
        <path d="M16.7 13.853c0-3.057.353-3.719.755-4.687.855-1.732 1.962-1.528 2.666-2.038.553-.407.654-.968.503-2.037-.1-.459-.201-.917-.352-1.376-.05-.102-.15-.204-.251-.204-1.107.255-2.164.764-3.12 1.427 0 0 0 .05-.05 0V3.817c0-.153-.1-.255-.251-.255h-3.471c-.15 0-.302.153-.302.306 0 .05.05.153.1.204.454.51 1.51 1.68 1.258 4.279-.1.815-.251 1.58-.452 2.394-.554 2.395-1.308 3.77-1.409 6.012-.05.917.151 1.834.654 2.598.05.05.1.102.201.102h4.729c.15 0 .251-.102.251-.255 0-.051 0-.102-.05-.153-.503-.611-1.408-2.292-1.408-5.196m60.313.204c0 .101-.05.153-.1.203a2.15 2.15 0 01-1.208.408c-.352 0-.704-.153-.956-.408a1.286 1.286 0 01-.251-.967v-2.955a1.29 1.29 0 01.251-.968c.252-.255.604-.357.956-.357.453.051.906.204 1.257.459.051.05.101.102.101.203l-.05 4.382zm0-9.323c-1.157-.968-2.616-1.528-4.125-1.528a6.92 6.92 0 00-3.169.917c-.15.102-.201.255-.1.407.603 1.02.955 2.242.905 3.465 0 3.056-2.062 5.094-2.062 7.793 0 2.7 1.408 4.483 4.476 4.483 1.409.052 2.767-.356 3.974-1.069h.101v2.496c0 .102-.15 1.477-1.911 1.477-1.46 0-1.811-.916-1.912-1.222 0-.051-.05-.102-.1-.102-1.46.102-2.919-.356-4.075-1.172-.101-.101-.252-.051-.353.051-.05.05-.05.102-.05.153v3.923c0 .05.05.102.05.152.353.357 2.264 2.038 6.44 2.038 2.112 0 3.973-.611 5.18-1.63 1.057-.866 1.41-1.885 1.41-3.72V8.657c0-.916.2-1.324.854-1.528.101-.05.151-.101.151-.255V3.868c0-.153-.1-.255-.251-.255h-5.232c-.15 0-.252.102-.252.255l.051.866z" />
        <path d="M64.287 12.987c0 .05 0 .102-.05.153-3.522 4.024-5.634-1.223-.1-2.344.05 0 .15.051.15.102v2.089zm2.867 2.853c0-3.057 2.063-5.248 2.063-7.846 0-3.311-2.113-4.737-6.087-4.737-3.118 0-4.779 1.171-4.879 2.037-.05.306.2.968.302 1.783.05.459.1.917.05 1.376 0 .153.1.254.201.254h2.113c.151 0 .252-.101.252-.254v-.56c0-.867.703-1.58 1.559-1.58 1.157 0 1.56.662 1.56 2.445v.255c0 .051 0 .051-.051.051-.805.204-1.862.408-2.868.713l-.552.153c-5.132 1.732-4.88 5.808-4.88 5.808 0 2.7 1.358 4.534 4.426 4.534 1.41 0 2.818-.356 4.025-1.12h.1v.813c0 .153.101.255.252.255h3.32c.151 0 .302-.153.302-.305 0-.052 0-.103-.05-.154a7.282 7.282 0 01-1.158-3.922z" />
        <path d="M51.611 13.19c0 1.02-.402 1.478-1.258 1.478-.402 0-.805-.102-1.106-.357-.05-.05-.1-.101-.1-.203V9.523c0-.102.05-.153.1-.204.352-.204.754-.357 1.157-.357.653 0 1.207.51 1.207 1.172v3.057zm3.219 1.274c.705-3.362 2.918-3.158 2.515-7.285-.452-4.279-4.829-3.922-4.829-3.922a4.745 4.745 0 00-3.27 1.12h-.1V.455c0-.153-.1-.255-.251-.255h-5.282c-.151 0-.252.102-.252.255V2.95c0 .204.1.459.302.56a5.998 5.998 0 011.86 2.344c.353.815.555 1.732.555 2.598 0 1.375-.352 2.7-.906 3.974-.553 1.222-1.106 2.394-1.106 3.514 0 1.376.402 4.076 6.388 4.28 1.61 0 3.22-.357 4.678-1.07a.59.59 0 00.2-.611c-.653-1.274-.803-2.7-.502-4.076z" />
        <path d="M39.186 13.14c0 .865-.402 1.324-1.056 1.324s-1.057-.459-1.057-1.325v-3.005c0-.866.403-1.325 1.057-1.325.654 0 1.056.459 1.056 1.325v3.005zm3.622 2.089c.302-2.446 1.962-3.821 1.962-6.878 0-2.395-1.76-5.146-6.69-5.146-2.616 0-4.075.816-4.679 1.223-.15.102-.201.306-.15.51.15.764.2 1.579.2 2.394 0 2.955-2.012 4.33-2.012 7.692 0 2.497 1.761 5.197 6.69 5.197 3.12 0 4.73-1.223 4.98-1.376a.49.49 0 00.152-.611 5.145 5.145 0 01-.453-3.005z" />
        <path d="M26.963 14.057c0 .101-.05.153-.101.203a2.15 2.15 0 01-1.207.408c-.352 0-.705-.153-.956-.408a1.288 1.288 0 01-.252-.967v-2.955c-.05-.357.05-.713.252-.968.251-.255.604-.357.956-.357.452.051.905.204 1.257.459.05.05.1.102.1.203v4.382h-.05zm3.219.815v-.408c0-3.311 2.012-4.789 2.012-7.03a8.721 8.721 0 00-.302-2.802c-.201-.611-.754-1.019-1.358-1.019h-3.32c-.15 0-.251.102-.251.255v.917l-.05-.05a6.585 6.585 0 00-4.025-1.53 4.51 4.51 0 00-1.157.154c-.15.05-.201.153-.201.305.201.815.302 1.681.302 2.548-.101 1.07-.403 1.986-2.264 2.547-1.56.458-1.912 3.515-1.56 6.622.302 2.497 1.61 4.89 4.78 4.89 1.408.052 2.816-.356 4.074-1.069h.1v2.496c0 .306-.301 1.477-1.911 1.477-1.459 0-1.81-.916-1.861-1.222 0-.051-.05-.102-.1-.102-1.46.102-2.918-.356-4.126-1.172-.1-.101-.251-.051-.352.051-.05.05-.05.102-.05.153v3.923c0 .05.05.102.05.152.352.357 2.264 2.038 6.439 2.038 2.113 0 3.974-.611 5.181-1.63 1.057-.866 1.409-1.885 1.409-3.72V20.12c.05-.612-.1-1.172-.453-1.63-.654-1.12-1.056-2.344-1.006-3.617zM7.696 8.911c0 .051-.05.102-.1.102H4.98c-.05 0-.1-.05-.1-.102v-.458c0-1.121.502-1.783 1.408-1.783.905 0 1.408.611 1.408 1.783v.458zM6.44 3.206C3.723 3.206 0 4.276 0 8.809v5.35c0 2.037.252 2.903.957 3.82 1.106 1.478 3.27 2.242 5.935 2.242 1.51.05 2.968-.153 4.427-.663.1-.05.201-.203.151-.305-.503-1.58-.503-3.312-.05-4.941 0-.052.05-.255-.201-.255h-3.27c-.1 0-.201.101-.252.203a1.455 1.455 0 01-1.408 1.121c-.704.051-1.358-.51-1.409-1.274v-1.63c0-.05.05-.102.1-.102h6.892c.101 0 .151-.05.202-.152.15-.51.905-3.108.905-4.636-.05-3.566-3.873-4.381-6.54-4.381z" />
      </g>
    </svg>
  )
}

export default BrandLogoErgobag
