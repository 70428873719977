import React from 'react'
import styled from 'styled-components'

import { vwpx } from '@/utils'

import BluesignLogoWhite from '@/icons/partner/PartnerBluesign'
import FearwearLogoWhite from '@/icons/partner/PartnerFearwear'
import GruenerKnopf from '@/images/gruener-knopf.jpg'

const PartnerLogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '30px',
  width: vwpx(190),
})

const CategoryBrand = () => (
  <PartnerLogoContainer>
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.bluesign.com/de"
    >
      <BluesignLogoWhite />
    </a>
    <a
      css={{
        marginTop: vwpx(25),
        width: vwpx(190),
        '@media(max-width: 500px)': {
          marginTop: vwpx(105),
          marginBottom: vwpx(145),
        },
      }}
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.fairwear.org/"
    >
      <FearwearLogoWhite />
    </a>
    <a
      css={{
        marginTop: vwpx(25),
        width: vwpx(190),
        '@media(max-width: 500px)': {
          marginTop: vwpx(45),
          marginBottom: vwpx(105),
          width: vwpx(200, 500),
        },
        '@media(min-width: 1920px)': {
          width: '75%',
        },
      }}
      rel="noopener noreferrer"
      target="_blank"
      href="https://vergabestelle.gruener-knopf.de/unternehmen/178"
    >
      <img
        css={{
          width: '55%',
          '@media(max-width: 500px)': {
            width: '80%',
            maxWidth: '100px',
          },
        }}
        src={GruenerKnopf}
        alt="gruener-knopf"
      />
    </a>
  </PartnerLogoContainer>
)

export default CategoryBrand
