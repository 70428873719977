import React from 'react'
import styled from 'styled-components'
import { Box, Composition } from 'atomic-layout'
import { useInView } from 'react-intersection-observer'
import { StaticQuery, graphql } from 'gatsby'

import CategoryBrand from './CategoryBrand'
import CategoryPartner from './CategoryPartner'
import CategoryLegal from './CategoryLegal'
import CategoryPrivacyPolice from './CategoryPrivacyPolice'

import Text from '@/components/Text'
import { desktopTemplateCols } from '@/theme'
import { fluidFontSize, vwpx } from '@/utils'

const ContactBlockContainer = styled('div')({
  padding: '75px 0px',
})

const TextLink = styled('a')({
  textDecoration: 'none',
  color: '#fff',
  fontWeight: 900,
})

const CategoryContainer = ({ headline, children }) => (
  <div>
    <Text
      type="subHeadingTextSerif"
      css={{
        fontSize: fluidFontSize(16),
        textTransform: 'uppercase',
        fontWeight: 400,
        fontFamily: 'Circular',
        '@media(max-width: 500px)': {
          fontWeight: 400,
          fontSize: vwpx(40),
          letterSpacing: '0.1em',
          marginBottom: vwpx(40),
        },
      }}
    >
      {headline}
    </Text>
    {children}
  </div>
)

const Footer = ({ onVisibilityChange, ...rest }) => {
  const isInitial = React.useRef(true)
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
  })
  const anchorId = 'kontakt'
  React.useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false
    } else {
      if (inView) {
        onVisibilityChange(anchorId)
      }
    }
  }, [inView, anchorId, onVisibilityChange])

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          contentfulPageContactBlock {
            headline
            linkHref
            linkLabel
            text {
              text
            }
          }
        }
      `}
      render={({
        contentfulPageContactBlock: {
          headline,
          linkHref,
          linkLabel,
          text: { text },
        },
      }) => (
        <Composition
          templateCols={desktopTemplateCols}
          css={{
            backgroundColor: '#000',
            paddingBottom: vwpx(200),
            '@media(max-width: 500px)': { paddingBottom: '10em' },
          }}
          {...rest}
        >
          <Box
            id={anchorId}
            colStart="inset-start"
            colEnd="inset-end"
            css={{
              color: '#fff',
              width: '100%',
              height: '100%',
            }}
          >
            <ContactBlockContainer ref={inViewRef}>
              <Text
                type="subHeading"
                css={{
                  margin: '0 auto 0.25em auto',
                  fontSize: fluidFontSize(100),
                  lineHeight: 1.2,
                  '@media(max-width: 500px)': {
                    fontSize: vwpx(143),
                  },
                }}
              >
                {headline}
              </Text>
              <Text
                type="subHeadingTextSerif"
                css={{
                  margin: '0 auto',
                  fontWeight: 400,
                  fontSize: fluidFontSize(50),
                  lineHeight: 1.2,
                  '@media(max-width: 500px)': {
                    fontSize: vwpx(87),
                  },
                }}
              >
                {text}
                <Text
                  type="teaserLink"
                  css={{
                    fontSize: fluidFontSize(16),
                    lineHeight: 1.6,
                    marginTop: '1.5em',
                    '@media(max-width: 500px)': {
                      fontSize: vwpx(46),
                      letterSpacing: '0.05em',
                    },
                  }}
                >
                  <TextLink href={`mailto:${linkHref}`}>
                    {linkLabel.toUpperCase()}
                  </TextLink>
                </Text>
              </Text>
            </ContactBlockContainer>
          </Box>
          <Box
            colStart="content-start"
            colEnd="content-end"
            css={{
              fontWeight: 900,
              backgroundColor: '#000',
              color: '#fff',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              gap: 20,
              marginTop: vwpx(100),
              '@media(max-width: 919px)': {
                gap: 10,
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
              },
              '@media(max-width: 500px)': {
                display: 'flex',
                flexDirection: 'column',
              },
            }}
          >
            <CategoryContainer headline="Address">
              <CategoryLegal />
            </CategoryContainer>
            <CategoryContainer headline="Legal">
              <CategoryPrivacyPolice />
            </CategoryContainer>
            <CategoryContainer headline="Partner">
              <CategoryPartner />
            </CategoryContainer>
            <CategoryContainer headline="Brands">
              <CategoryBrand />
            </CategoryContainer>
          </Box>
        </Composition>
      )}
    />
  )
}

export default Footer
